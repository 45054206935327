<template>
  <div>
    <form @submit.prevent="doLogin()" v-if="$route.params.profile" class="form-login" :style="'--featured: ' + customersArea.featured + '; --featured-dark: ' + customersArea.featuredDark + '; --featured-light: ' + customersArea.featuredLight">
      <div class="container-fluid">
        <div class="row">
          <div class="card card-login">
            <div class="w-100 text-center">
              <img v-if="customersArea.logoUrl" class="img-fluid logo-img" style="height: 70px; margin: 25px 0 30px; " :src="customersArea.logoUrl">
            </div>
            <div class="card-header">
              <h2>Área do cliente</h2>
              <p>Seja bem-vindo ao futuro das contabilidades.</p>
            </div>
            <div class="card-body">
              <div class="row">
                <div style="z-index: 1;">
                  <svg stroke="#9496A1" style="margin-left: 30px;" width="1.5em" height="2.8em" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 17C1 14.5 3 12.6 5.4 12.6H10.5C13 12.6 14.9 14.6 14.9 17" stroke="#9496A1" stroke-width="1.4824" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M11 2.2C12.7 3.9 12.7 6.6 11 8.2C9.30001 9.8 6.60001 9.9 5.00001 8.2C3.40001 6.5 3.30001 3.8 5.00001 2.2C6.70001 0.6 9.30001 0.6 11 2.2" stroke="#9496A1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
                <div class="col" style="margin-left: -54px;">
                  <div class="form-group">
                    <input
                      style="padding-left: 45px;"
                      type="text"
                      v-model="documentNumber"
                      class="form-control"
                      placeholder="CNPJ ou CPF"
                      v-mask="mask"
                      :change="changeMask()"
                      required
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div style="z-index: 1;">
                  <svg stroke="#9496A1" style="margin-left: 31px;" width="1.5em" height="2.8em" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M13 19H3C1.895 19 1 18.105 1 17V10C1 8.895 1.895 8 3 8H13C14.105 8 15 8.895 15 10V17C15 18.105 14.105 19 13 19Z" stroke="#9496A1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M4 8V5V5C4 2.791 5.791 1 8 1V1C10.209 1 12 2.791 12 5V5V8" stroke="#9496A1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
                <div class="col" style="margin-left: -55px;">
                  <div class="form-group">
                    <input
                      style="padding-left: 45px;"
                      type="password"
                      v-model="password"
                      class="form-control"
                      placeholder="Senha de Acesso"
                      required
                    >
                  </div>
                </div>
              </div>
              <button class="btn btn-primary w-100" :disabled='loading'>
                <template v-if="loading">
                  <i class="fas fa-circle-notch fa-spin"></i>
                </template>
                <template v-else>
                  <svg width="24" height="24" style="transform: translate(0, 0px); margin-left: -24px" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.9996 9.99902L15.0008 13.0003L11.9996 16.0015" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M15.0098 13.0001H5.18274C4.43558 12.9995 3.83018 12.3937 3.83018 11.6465V11.6465C3.83047 9.76444 4.57613 7.9591 5.90404 6.6254V6.6254C7.69303 4.83356 10.1218 3.82777 12.6539 3.83016C17.1932 3.8116 21.0656 7.11115 21.7678 11.5959C22.47 16.0806 19.7914 20.4059 15.4636 21.7756C11.1358 23.1453 6.45644 21.1488 4.45044 17.0768" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>Entrar
                </template>
              </button>
              <div class="row container" style="color: #c1c1c1 !important">
                <p style="font-size: 14px;">Nosso Suporte: {{ customersArea.supportContact }}</p>
              </div>
              <img class="img-fluid logo-img" style="height: 35px; margin-top: 30px;" src="../../assets/images/svg/sh-logo-login-gray.svg">

            </div>
          </div>
        </div>
      </div>
      <div v-if="status.info" class="alert text-center" :class="status.color" role="alert">
        {{status.info}}
      </div>
    </form>
    <form @submit.prevent="verifyUsername()" v-else class="form-login" :style="'--featured: ' + customersArea.featured + '; --featured-dark: ' + customersArea.featuredDark + '; --featured-light: ' + customersArea.featuredLight">
      <div class="container-fluid">
        <div class="row">
          <div class="card card-login card-select">
            <div class="card-header pb-0">
              <h2>Qual a sua contabilidade?</h2>
            </div>
            <div class="card-body pt-0">
              <div class="form-group mt-0 pt-0">
                <p class="mb-0 mt-0 font-weight-bold">Nome de Usuário da Contabilidade</p>
                <input
                  type="text"
                  class="form-control"
                  placeholder="@minhacontabilidade"
                  v-model="username"
                  autocapitalize="none"
                  required
                  :change="username.length ? username = '@' + username.substring(0, 30).toLowerCase().replace(/[^a-zA-Z0-9]/g, '') : ''"
                >
              </div>
              <button class="btn btn-primary w-100" :disabled='loading'>
                <template v-if="loading">
                  <i class="fas fa-circle-notch fa-spin"></i>
                </template>
                <template v-else>
                  <svg width="24" height="24" style="transform: translate(0, -2px); margin-left: -24px" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.9996 9.99902L15.0008 13.0003L11.9996 16.0015" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M15.0098 13.0001H5.18274C4.43558 12.9995 3.83018 12.3937 3.83018 11.6465V11.6465C3.83047 9.76444 4.57613 7.9591 5.90404 6.6254V6.6254C7.69303 4.83356 10.1218 3.82777 12.6539 3.83016C17.1932 3.8116 21.0656 7.11115 21.7678 11.5959C22.47 16.0806 19.7914 20.4059 15.4636 21.7756C11.1358 23.1453 6.45644 21.1488 4.45044 17.0768" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>Selecionar
                </template>
              </button>
              <img class="img-fluid logo-img" style="height: 35px; margin-top: 30px;" src="../../assets/images/svg/sh-logo-login-gray.svg">

            </div>
          </div>
        </div>
      </div>
      <div v-if="status.info" class="alert text-center" :class="status.color" role="alert">
        {{status.info}}
      </div>
    </form>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'Login',
  data: () => {
    return {
      username: '',
      loading: false,
      documentNumber: '',
      password: '',
      status: {
        info: '',
        color: ''
      },
      customersArea: {
        featured: '#13cf9a',
        featuredDark: '#13cf9a',
        featuredLight: '#13cf9a'
      },
      uId: '',
      mask: '',
      cookiesInfos: {
        customerId: '',
        cid: '',
        counterAccess: false
      }
    }
  },
  async mounted () {
    if (this.$route.params.profile) {
      await this.getCustomerArea()
      await this.verifyLoginWithRouter()
    } else {
      this.$root.$emit('Spinner::hide')
    }
  },
  methods: {
    sleep (ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    async changeMask () {
      await this.sleep(150)
      const documentNumber = this.documentNumber
      if (documentNumber.replace(/[^0-9]/g, '').length > 11) {
        this.mask = '##.###.###/####-##'
      } else if (documentNumber.replace(/[^0-9]/g, '').length < 11 && documentNumber.replace(/[^0-9]/g, '').length > 0) {
        this.mask = '###.###.###-##'
      } else {
        this.mask = ''
      }
    },
    hexToRgbA (hex, opacity) {
      var c
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('')
        if (c.length === 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]]
        }
        c = '0x' + c.join('')
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')'
      }
    },
    async verifyUsername () {
      this.loading = true
      if (this.username.includes('@')) {
        await this.$firebase.database().ref(`simplesconnect/profiles/${this.username}`).once('value', snapshot => {
          const profile = snapshot.val()
          if (profile) {
            console.log(profile)
            window.location.href = '/' + this.username
          } else {
            this.status = {
              info: 'Contabilidade não encontrada',
              color: 'status-danger'
            }
          }
        })
      } else {
        this.status = {
          info: 'Contabilidade não encontrada',
          color: 'status-danger'
        }
      }
      this.loading = false
      setTimeout(() => {
        this.status = {
          info: '',
          color: ''
        }
      }, 3000)
    },
    async getCustomerArea () {
      this.$root.$emit('Spinner::show')
      this.$cookie.delete('profile')
      await this.$firebase.auth().signOut()
      if (this.$route.params.profile.includes('@')) {
        await this.$firebase.database().ref(`simplesconnect/profiles/${this.$route.params.profile}`).once('value', snapshot => {
          const profile = snapshot.val()
          if (profile) {
            this.uId = profile.uId
          } else {
            window.location.href = 'https://clientes.simpleshub.com.br/'
          }
        })
      } else {
        window.location.href = 'https://clientes.simpleshub.com.br/'
      }

      await this.$firebase.database().ref(`simplesconnect/users/${this.uId}/customersArea`).once('value', snapshot => {
        const customersArea = snapshot.val()
        if (customersArea) {
          this.customersArea = { ...this.customersArea, ...customersArea }
          this.customersArea.featured = this.hexToRgbA(customersArea.primaryColor, '.80')
          this.customersArea.featuredLight = this.hexToRgbA(customersArea.primaryColor, '.60')
          this.customersArea.featuredDark = this.hexToRgbA(customersArea.primaryColor, '1')
        }
      })
    },
    async verifyLoginWithRouter () {
      this.$root.$emit('Spinner::show')
      if (this.$route.params.document && this.$route.params.password) {
        await await this.$firebase.auth().signOut()
        const documentNumber = this.$route.params.document.replace(/[^0-9]/g, '')
        const password = this.$route.params.password

        let customerAccessEmail = null
        await this.$firebase.database().ref(`simplesconnect/users/${this.uId}/customersData/${documentNumber}`).once('value', snapshot => {
          const customer = snapshot.val()
          if (customer && customer.customerAccessEmail) {
            customerAccessEmail = customer.customerAccessEmail
            this.cookiesInfos.customerId = customer.customerId
          } else {
            this.status = {
              info: 'CNPJ ou CPF não encontrado',
              color: 'alert-danger'
            }
            setTimeout(() => {
              this.loading = false
              this.status = {
                info: '',
                color: ''
              }
            }, 5000)
            return null
          }
        })

        try {
          var res = await this.$firebase.auth().signInWithEmailAndPassword(customerAccessEmail, password)
          window.uid = res.user.uid
          Vue.prototype.$profile = { uid: res.user.uid }
          this.cookiesInfos.uid = res.user.uid
          this.cookiesInfos.cid = this.uId
          this.cookiesInfos.counterAccess = true
          this.$cookie.set('profile', JSON.stringify(this.cookiesInfos), { expires: 'Fri, 31 Dec 9999 23:59:59 GMT' })
          this.$router.push({ name: 'home' })
        } catch (err) {
          this.status = {
            info: 'Senha Inválida',
            color: 'alert-danger'
          }
          this.$root.$emit('Spinner::hide')
          setTimeout(() => {
            this.loading = false
            this.status = {
              info: '',
              color: ''
            }
          }, 2000)
          return err
        }
        setTimeout(() => {
          this.loading = false
          this.status = {
            info: '',
            color: ''
          }
          this.$root.$emit('Spinner::hide')
        }, 5000)
      } else {
        setTimeout(() => {
          this.$root.$emit('Spinner::hide')
        }, 500)
      }
    },
    async doLogin () {
      this.loading = true
      this.$cookie.delete('profile')
      const documentNumber = this.documentNumber.replace(/[^0-9]/g, '')
      const password = this.password

      let customerAccessEmail = null
      await this.$firebase.database().ref(`simplesconnect/users/${this.uId}/customersData/${documentNumber}`).once('value', snapshot => {
        const customer = snapshot.val()
        console.log(customer)
        if (customer && customer.customerAccessEmail) {
          customerAccessEmail = customer.customerAccessEmail
          this.cookiesInfos.customerId = customer.customerId
        } else {
          this.status = {
            info: 'CNPJ ou CPF não encontrado',
            color: 'alert-danger'
          }
          setTimeout(() => {
            this.loading = false
            this.status = {
              info: '',
              color: ''
            }
          }, 5000)
          return null
        }
      })

      try {
        var res = await this.$firebase.auth().signInWithEmailAndPassword(customerAccessEmail, password)
        window.uid = res.user.uid
        Vue.prototype.$profile = { uid: res.user.uid }
        this.cookiesInfos.uid = res.user.uid
        this.cookiesInfos.cid = this.uId
        this.$cookie.set('profile', JSON.stringify(this.cookiesInfos), { expires: 'Fri, 31 Dec 9999 23:59:59 GMT' })
        this.$router.push({ name: 'home' })
      } catch (err) {
        this.status = {
          info: 'Senha Inválida',
          color: 'alert-danger'
        }
        setTimeout(() => {
          this.loading = false
          this.status = {
            info: '',
            color: ''
          }
        }, 2000)
        return err
      }

      setTimeout(() => {
        this.loading = false
        this.status = {
          info: '',
          color: ''
        }
      }, 5000)
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (window.uid && (!to.params.email && !to.params.password)) {
        vm.$router.push({ name: 'home' })
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.form-login {
  background-color: var(--featured-light);
  height: 100%;
  width: 100%;
  position: fixed;
  display: block;
  justify-content: left;
  align-items: center;
  .right-side{
    display: flex;
    position: fixed;
    padding: 50px 0 90px;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    color: white;
    text-align: right;
    padding: 60px 80px 80px;
    .title{
      font-size: 65px;
      font-weight: 800;
    }
    .subtitle{
      font-weight: 400;
      font-size: 23px;
    }
    .logo-img{
      display: block;
      margin-left: auto;
      width: 250px;
      height: auto;
    }
  }
  .card-select {
    height: 345px !important;
  }
  .card-login {
    width: 480px;
    height: 595px;
    padding: 20px;
    border: none;
    background: white;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    border: solid 2px var(--featured);
    // -webkit-box-shadow: 0px 0px 18px 8px rgba(0,0,0,0.1);
    // box-shadow: 0px 0px 18px 8px rgba(0,0,0,0.1);
    // border-bottom-left-radius: 0 !important;
    // border-top-left-radius: 0 !important;
    input{
      &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: #aaa !important;
      }
      &::-webkit-input-placeholder {
        color: #aaa !important;
      }
    }
    .card-header{
      border: none;
      background: white;
      h2, p {
        margin: 15px 0 0px;
        text-align: left;
      }
      h2{
        font-weight: 800 !important;
        font-size: 26px;
      }
    }
    p {
      font-weight: 400 !important;
      margin-bottom: 0;
      margin-top: 20px !important;
    }
    a {
      margin-top: 0 !important;
    }
    .btn-link {
      margin: 35px 3px 15px;

      &router-link{

      }
    }
  }
}
</style>
